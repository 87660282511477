import ReactFullpage from "@fullpage/react-fullpage";
import SectionFirst from "../component/Section_First";
import Section_Second from "../component/Section_Second";
import Section_Third from "../component/Section_Third";
import Section_Four from "../component/Section_Four";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "antd";
import ScrollFixedButton from "../component/ScrollFixedButton";
import { useEffect, useState } from "react";
import btn_top from "../img/common/btn_top.gif";
// import { Header } from "antd/es/layout/layout";
import { Helmet } from "react-helmet-async";
import {
  FullpageContainer,
  FullpageSection,
} from "@shinyongjun/react-fullpage";
import { Carousel } from "antd";

const Index = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <StyledMain2>
      <FullpageContainer
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      >
        <FullpageSection>
          <SectionFirst />
        </FullpageSection>
        <FullpageSection>
          <Section_Second />
        </FullpageSection>
        <FullpageSection>
          <div className="third_layout">
            <StyledMain>
              <StyledButton
                type="button"
                className={`${activeIndex === 0 ? "active" : ""}`}
                onClick={() => setActiveIndex(0)}
              >
                <img src={btn_top} alt="btn_top" />
              </StyledButton>
            </StyledMain>
            <Section_Third />
          </div>
        </FullpageSection>
        <FullpageSection isAutoHeight>
          <footer>
            <Section_Four />
          </footer>
        </FullpageSection>
      </FullpageContainer>
    </StyledMain2>
  );
};

export default Index;

const StyledMain = styled(Button)`
  position: absolute;
  right: 30px; /* 오른쪽 여백 */
  bottom: 50px; /* 아래쪽 여백 */

  border: none;
  border-radius: 5px;
  cursor: pointer;

  img {
    width: 50px;
    height: 50px;
    border-radius: 20px;
  }

  button {
    border: none;
    background-color: white;
  }
`;

const StyledMain2 = styled.div`
  .react-fullpage__contents {
    display: unset !important;
  }

  .one_layout {
    height: 100vh;
  }

  .third_layout {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }

  .react-fullpage__contents {
    display: flex;
    justify-content: center; /* 가로 중앙 정렬 */
    align-items: center; /* 세로 중앙 정렬 */
    height: 100vh; /* 전체 화면 높이 */
  }
`;

const StyledButton = styled.button`
  display: block; // 기본적으로 보이도록 설정
  border: none; // 필요에 따라 테두리 제거
  background: transparent; // 배경을 투명하게 설정
  padding: 0; // 패딩 제거

  // 470px 이하에서는 버튼 숨기기
  @media (max-width: 470px) {
    display: none;
  }
`;
