import { Button, Table } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BasicText from "./BasicText";
import Colors from "./Colors";
import PrivacyHeader from "./PrivacyHeader";

import {
  autoDataSource,
  dataSource,
  mustDataSource,
  privacyDataSource,
  informationColmun,
  privacyColmun,
  otherDataSource,
  otherColmun,
  witakDataSource,
  witakColmun,
  inColmun,
  inDataSource,
  relationDataSource,
  relationColmun,
  protectedDataSource,
  protectedColmun,
  historyDataSource,
  historyColmun,
} from "./tablePrivcay";

// 개인민감정보처리방침
const Information = () => {
  const nvaigate = useNavigate();

  const StyleSubTitle = ({ children }) => (
    <>
      <br />
      <br />
      <BasicText bold={700} size={18} color={Colors.text}>
        {children}
      </BasicText>
      <br />
      <br />
    </>
  );

  const StyleTitle = ({ children }) => (
    <>
      <br />
      <br />
      <BasicText bold={700} size={23} color={Colors.primary}>
        {children}
      </BasicText>
      <br />
      <br />
    </>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PrivacyHeader />

      <StyledMainContainer>
        <div className="text-area">
          <StyleTitle bold={700} size={23} color={Colors.primary}>
            개인정보 처리방침
            <br /> <br />
          </StyleTitle>

          <div>
            <BasicText bold={400} color={Colors.gray4}>
              주식회사 (주)엠에이디씨(이하 회사)는 나비톡 서비스(어플리케이션 및
              웹사이트 포함)의 운영을 위해 이용자의 동의를 기반으로 개인정보를
              수집•이용 및 제공하고 있으며, 이용자의 권리를 보호하기 위해
              「개인정보 보호법」 등 관련 법령 및 가이드라인을 준수합니다. 본
              방침은 나비톡 서비스를 이용하는 과정에서 이용자가 제공한
              개인정보가 어떻게 처리되고 있는지 투명하게 안내하기 위해
              마련되었습니다.
            </BasicText>
            <StyleSubTitle>1. 개인정보의 수집</StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              회사는 나비톡 서비스(어플리케이션 및 웹사이트 포함)를 운영하기
              위해 아래와 같은 개인정보를 수집하며, 필수정보와 선택 정보, 자동
              수집 및 생성 정보들 구분하여이 수집합니다.
            </BasicText>
            <br />
            <br />
            <BasicText bold={400} color={Colors.gray4}>
              [필수정보]
            </BasicText>
            <br />
            <BasicText bold={400} color={Colors.gray4}>
              <Table
                dataSource={mustDataSource}
                columns={informationColmun}
                pagination={false}
              />
              <br />
              <br />
              [선택정보]
              <br />
              <Table
                pagination={false}
                dataSource={dataSource}
                columns={informationColmun}
              />
              <br />
              <br />
              <br />
              [자동 수집 및 생성 정보]
              <br />
              <Table
                pagination={false}
                dataSource={autoDataSource}
                columns={informationColmun}
              />
            </BasicText>
            <StyleSubTitle>2. 개인정보의 이용</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              보의 이용 회사는 나비톡 서비스의 원활한 제공을 위해 수집된
              개인정보를 다음의 목적으로 사용합니다.
              <br />
              <Table
                pagination={false}
                dataSource={privacyDataSource}
                columns={privacyColmun}
              />
              <br />
              회사는 수집한 개인정보를 목적 외의 용도로 이용하지 않으며, 이용
              목적이 변경되는 경우 사전에 동의를 받습니다.
            </BasicText>
            <StyleSubTitle>3. 개인정보의 제공 및 위탁</StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              회사는 이용자로부터 사전 동의를 받은 범위 내에서만 개인정보를
              제3자에게 제공합니다. 회사가 개인정보를 제공하는 제3자는 다음과
              같습니다.
              <br />
              <br />
              ① 이용자가 사전에 동의한 경우
              <br /> ② 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진
              절차와 방법에 따라 수사기관의 요구가 있는 경우 개인정보 처리 위탁
              <br />
              <br />
              [개인정보 제3자 제공]
              <br />
              <Table
                pagination={false}
                dataSource={otherDataSource}
                columns={otherColmun}
              />
              <br />
              <br />
              [개인정보 처리 위탁] <br />
              회사는 서비스 제공을 위해 필요한 업무 중 일부를 외부 업체에
              위탁하고 있으며, 위탁 받은 업체가 개인정보 보호 법령을 준수하도록
              관리·감독하고 있습니다.
              <br />
              <Table
                pagination={false}
                dataSource={witakDataSource}
                columns={witakColmun}
              />
            </BasicText>
            <StyleSubTitle>4. 개인정보의 파기</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              회사는 개인정보의 수집 및 이용 목적이 달성된 후에는 해당 정보를
              지체 없이 파기합니다. <br />
              <br />
              [파기 절차] 파기 사유가 발생한 개인정보를 선정하고, 개인정보
              보호책임자의 승인하에 개인정보를 파기합니다. <br />
              <br />
              [파기 방법]
              <br /> ① 전자적 파일 형태: 복구 및 재생이 불가능한 방법으로 영구
              삭제합니다.
              <br /> ② 종이 문서: 분쇄하거나 소각을 통해 파기합니다.
            </BasicText>
            <br />
            <br />
            <BasicText bold={400} color={Colors.gray4}>
              [내부 방침에 따라 일정기간 보관 후 파기하는 정보]
              <br />
              <Table
                pagination={false}
                dataSource={inDataSource}
                columns={inColmun}
              />
            </BasicText>
            <BasicText bold={400} color={Colors.gray4}>
              <br />
              <br />
              [관계법령에 따른 개인정보 보관]
              <br />
              <Table
                pagination={false}
                dataSource={relationDataSource}
                columns={relationColmun}
              />
            </BasicText>
            <StyleSubTitle>5. 이용자 및 법적대리인의 권리</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              이용자 및 법정대리인은 언제든지 다음의 권리를 행사할 수 있습니다.{" "}
              <br />① 개인정보 열람 요청 <br />② 개인정보 오류 등이 있을 경우
              정정 요청 <br />③ 개인정보 삭제 요청 <br />④ 개인정보 처리 정지
              요청 <br />※ 권리 행사는 회사의 개인정보 보호책임자 및 담당 부서로
              연락하여 요청할 수 있으며, 회사는 지체 없이 필요한 조치를
              취하겠습니다.
            </BasicText>
            <StyleSubTitle>
              6. 개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항
            </StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              [쿠키의 사용] <br />
              회사는 맞춤형 서비스 제공을 위해 쿠키를 사용합니다.
              <br />① 쿠키란? 웹사이트를 운영하는 데 이용되는 서버가 이용자의
              브라우저에 보내는 아주 작은 텍스트 파일로, 이용자의 디바이스에
              저장됩니다. <br />② 사용 목적: 접속 빈도나 방문 시간 등을 분석하여
              맞춤형 서비스 제공 및 서비스 개선에 활용합니다. <br />
              <br /> [쿠키 설정 거부 방법]
              <br /> 웹 브라우저 상단의 도구 {">"} 인터넷 옵션 {">"} 개인정보
              메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다. 단, 쿠키
              저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 있을 수 있습니다.
            </BasicText>
            <StyleSubTitle>
              7. 행태정보 수집 이용 및 거부 등에 관한 사항
            </StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              회사는 서비스 이용과 관련한 행태정보를 수집하여 맞춤형 서비스를
              제공합니다.
              <br /> ① 수집 항목: 서비스 이용 기록, 검색 기록, 접속 기록 등{" "}
              <br />② 수집 목적: 맞춤형 콘텐츠 추천, 서비스 개선, 마케팅 활용{" "}
              <br />③ 보유 기간: 수집일로부터 1년간 보관 후 파기 <br />④ 거부
              방법: 웹 브라우저의 쿠키 설정을 변경하여 행태정보 수집을 거부할 수
              있습니다.
            </BasicText>
            <StyleSubTitle>8. 개인정보의 안전성 확보 조치</StyleSubTitle>{" "}
            <BasicText bold={400} color={Colors.gray4}>
              회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
              있습니다. <br />① 관리적 조치: 내부관리계획 수립∙시행, 직원 교육
              등 <br />② 기술적 조치: 개인정보 처리 시스템의 접근 권한 관리,
              접근 통제 시스템 설치, 고유식별정보 등의 암호화 <br />③ 물리적
              조치: 전산실, 자료 보관실 등의 접근 통제
            </BasicText>
            <StyleSubTitle>9. 개인정보 보호책임자 및 담당부서</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
              처리와 관련한 이용자의 불만 처리 및 피해 구제 등을 위해 아래와
              같이 개인정보 보호책임자 및 담당부서를 지정하고 있습니다.
              <br />
              <br />
              <Table
                pagination={false}
                dataSource={protectedDataSource}
                columns={protectedColmun}
              />{" "}
            </BasicText>
            <StyleSubTitle>
              10. 정보주체의 권익침해에 대한 구제방법
            </StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해 구제, 상담
              등을 문의하실 수 있습니다.
              <br />
              <br />① 개인정보침해신고센터 <br />
              홈페이지: privacy.kisa.or.kr
              <br />
              전화: 국번 없이 118 <br />② 개인정보 분쟁조정위원회
              <br />
              홈페이지: kopico.go.kr
              <br />
              전화: 1833-6972 <br />③ 대검찰청 사이버수사과
              <br />
              홈페이지: spo.go.kr
              <br />
              전화: 국번 없이 1301 <br />④ 경찰청 사이버안전국
              <br />
              홈페이지: cyberbureau.police.go.kr
              <br />
              전화: 국번 없이 182
            </BasicText>
            <StyleSubTitle>
              11. 개인정보 처리방침 변경에 관한 사항
            </StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              회사는 개인정보 처리방침을 변경하는 경우, 변경된 내용을 최소 7일
              전부터 공지사항을 통해 고지합니다. 다만, 이용자의 권리 또는 의무에
              중요한 내용의 변경은 최소 30일 전에 공지합니다.
              <br />
              <br />
              [개정 이력]
              <br />
              <br />
              <Table
                pagination={false}
                dataSource={historyDataSource}
                columns={historyColmun}
              />{" "}
            </BasicText>
            <StyleSubTitle>12. 부칙</StyleSubTitle>
            <BasicText bold={400} color={Colors.gray4}>
              이 개인정보 처리방침은 2024. 10. 20.(일)부터 시행됩니다.
            </BasicText>
          </div>
          <Button
            onClick={() => nvaigate(-1)}
            style={{
              marginTop: 20,
              width: 200,
            }}
          >
            확인
          </Button>
        </div>
      </StyledMainContainer>
    </>
  );
};

export default Information;

const StyledMainContainer = styled.div`
  background-color: #f5f0ee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text-area {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
  }
`;
