// 필수, 선택정보, 자동 컬럼--
export const informationColmun = [
  {
    title: '서비스구분',
    dataIndex: 'service',
    key: 'service',
    width: '20%',
  },
  {
    title: '수집항목',
    dataIndex: 'imforemation',
    key: 'imforemation',
  },
  {
    title: '수집 방법',
    dataIndex: 'method',
    key: 'method',
    width: '20%',
  },
  {
    title: '보유 및 이용기간',
    dataIndex: 'data',
    key: 'data',
    width: '20%',
  },
]

// 필수 정보
export const mustDataSource = [
  {
    key: '1',
    service: '(개인)회원가입_이메일',
    imforemation: '이메일주소 / 비밀번호 / 닉네임 / 관심사 / 고민부위',
    method: '이용자 본인 제공',
    data: '회원 탈퇴 시 까지',
  },
  {
    key: '2',
    service: '(개인)회원가입_카카오',
    imforemation: '닉네임 / 관심사 / 고민부위',
    method: '로그인시 자동 수집, 이용자 본인제공',
    data: '회원 탈퇴 시 까지',
  },
  {
    key: '3',
    service: '(개인)회원가입_네이버',
    imforemation: '이름 / 이메일주소 / 닉네임 / 관심사 / 고민부위',
    method: '로그인시 자동 수집, 이용자 본인제공',
    data: '회원 탈퇴 시 까지',
  },
  {
    key: '4',
    service: '(개인)회원가입_구글',
    imforemation: '닉네임 / 관심사 / 고민부위',
    method: '로그인시 자동 수집, 이용자 본인제공',
    data: '회원 탈퇴 시 까지',
  },
  {
    key: '5',
    service: '(개인)회원가입_애플',
    imforemation: '이메일주소 / 닉네임 / 관심사 / 고민부위',
    method: '로그인시 자동 수집, 이용자 본인제공',
    data: '회원 탈퇴 시 까지',
  },
  {
    key: '6',
    service: '(병원)관리자 가입',
    imforemation:
      'ID / 비밀번호 / 대표자명 / 담당자명 / 담당자이메일 / 담당자 번호 / 직급 / 부서명',
    method: '이용자 본인제공',
    data: '회원 탈퇴 시 까지',
  },
  {
    key: '7',
    service: '본인인증(PASS)',
    imforemation: '이름 / 휴대전화번호 / 통신사',
    method: '본인인증 시 자동수집',
    data: '인증 완료 후 즉시 파기',
  },
  {
    key: '8',
    service: '위치기반 서비스 이용',
    imforemation: '위치정보',
    method: '이용중 자동 수집 ',
    data: '회원 탈퇴 시 까지',
  },
]

// 선택 정보
export const dataSource = [
  {
    key: '1',
    service: '커뮤니티(채팅, 게시글)',
    imforemation: '게시글, 메세지의 텍스트, 사진',
    method: '이용자 본인 제공',
    data: '회원 탈퇴 시 까지 또는 삭제 요청 시 까지',
  },
  {
    key: '2',
    service: '나비록(민감정보)',
    imforemation: '수술시기 / 병원명 / 수술 전 후 사진',
    method: '이용자 본인제공',
    data: '회원 탈퇴 시 까지 또는 삭제 요청 시 까지',
  },
  {
    key: '3',
    service: '이벤트 신청(상담)',
    imforemation: '이름 / 휴대전화번호',
    method: '이용자 본인제공',
    data: '상담 종료 후 1년',
  },
  {
    key: '4',
    service: '(개인)회원가입_네이버',
    imforemation: '프로필 사진/ 연령대',
    method: '로그인시 자동 수집, 이용자 본인제공',
    data: '회원 탈퇴 시 까지',
  },
]

// 자동 정보
export const autoDataSource = [
  {
    key: '1',
    service: '단말기 정보(OS, 디바이스 ID, 폰 기종 등)',
    imforemation: '서비스 최적화 및 부정 이용 방지',
    method: '서비스 이용중 자동수집',
    data: '회원 탈퇴 시 까지',
  },
  {
    key: '2',
    service: 'IP 주소, 쿠키, 방문 일시, 서비스 이용 기록(검색기록)',
    imforemation: '맞춤형 이벤트 제공 및 서비스 개선',
    method: '서비스 이용중 자동수집',
    data: '회원 탈퇴 시 까지',
  },
  {
    key: '3',
    service: '부정 이용 기록',
    imforemation: '부정 이용 방지 및 대응',
    method: '서비스 이용중 자동수집',
    data: '회원 탈퇴 시 까지',
  },
  {
    key: '4',
    service: '위치기반 서비스 이용',
    imforemation: '위치 정보',
    method: '이용중 자동 수집',
    data: '회원 탈퇴 시 까지',
  },
]

// privacy
export const privacyColmun = [
  {
    title: '이용 목적',
    dataIndex: 'using',
    width: '50%',
  },
  {
    title: '이용 항목',
    dataIndex: 'usingList',
    width: '50%',
  },
]

export const privacyDataSource = [
  {
    key: '1',
    using: '회원 가입 및 회원 관리',
    usingList: '이름 / 이메일주소 / 비밀번호 / 닉네임 / 관심사 / 고민부위',
  },
  {
    key: '2',
    using: '본인 식별 및 인증',
    usingList: '이름 / 휴대전화번호 / 통신사',
  },
  {
    key: '3',
    using: '(병원)유로 서비스 제공 및 요금 정산',
    usingList: '예금주명 / 계좌번호 / 이체내역',
  },
  {
    key: '4',
    using: '서비스의 부정 이용 방지 및 비인가 사용 방지',
    usingList: '단말기 정보 / IP 주소 / 부정 이용기록',
  },
  {
    key: '5',
    using: '맞춤형 콘텐츠 제공 및 마케팅 활용',
    usingList: '서비스 이용 기록 / 접속 빈도 / 관심사 / 고민부위 / 쿠키',
  },
  {
    key: '6',
    using: '위치 기반 서비스 제공',
    usingList: '위치정보 ',
  },
]

// 제 3자 제공 ----------
export const otherColmun = [
  {
    title: '개인정보를 제공 받는 자',
    dataIndex: 'privacy',
    width: '25%',
  },
  {
    title: '제공하는 개인정보',
    dataIndex: 'givingInformation',
    width: '25%',
  },
  {
    title: '이용 목적',
    dataIndex: 'usingInformation',
    width: '25%',
  },
  {
    title: '보유 및 이용기간',
    dataIndex: 'date',
    width: '25%',
  },
]

export const otherDataSource = [
  {
    key: '1',
    privacy: '이용자가 이벤트 신청을 한 병원',
    givingInformation: '이름 / 전화번호 / 연락가능시간',
    usingInformation: '상당 및 예약',
    date: '상담 종료 후 1년',
  },
]

// 위탁 제공 ----------
export const witakColmun = [
  {
    title: '수탁업체',
    dataIndex: 'service',
    width: '25%',
  },
  {
    title: '위택 내용',
    dataIndex: 'witak',
    width: '25%',
  },
  {
    title: '제공 학목',
    dataIndex: 'givingInformation',
    width: '25%',
  },
  {
    title: '보유 및 이용기간',
    dataIndex: 'date',
    width: '25%',
  },
]

export const witakDataSource = [
  {
    key: '1',
    service: '드림시큐리티',
    witak: '본인인증 서비스(PASS)',
    givingInformation: '이름 / 휴대전화번호 / 통신사',
    date: '인증 완료 후 즉시파기',
  },
  {
    key: '2',
    service: 'Amazon Web Services',
    witak: '시스템 제공 및 관리',
    givingInformation: '시스템 운영 관련 정보',
    date: '서비스 종료 시 까지',
  },
  {
    key: '3',
    service: '카카오',
    witak: 'SNS 가입 여부 및 계정 정보 확인',
    givingInformation: '이용자 식별값',
    date: '회원 탈퇴 시 까지',
  },
  {
    key: '4',
    service: '네이버',
    witak: 'SNS 가입 여부 및 계정 정보 확인',
    givingInformation: '이용자 식별값',
    date: '회원 탈퇴 시 까지',
  },
  {
    key: '5',
    service: '구글',
    witak: 'SNS 가입 여부 및 계정 정보 확인',
    givingInformation: '이용자 식별값',
    date: '회원 탈퇴 시 까지',
  },
  {
    key: '6',
    service: '애플',
    witak: 'SNS 가입 여부 및 계정 정보 확인',
    givingInformation: '이용자 식별값',
    date: '회원 탈퇴 시 까지',
  },
]

// 내부방침 제공 ----------
export const inColmun = [
  {
    title: '보관 항목',
    dataIndex: 'item',
    width: '33%',
  },
  {
    title: '보관 기간',
    dataIndex: 'date',
    width: '33%',
  },
  {
    title: '보관 사유',
    dataIndex: 'reason',
    width: '33%',
  },
]

export const inDataSource = [
  {
    key: '1',
    item: '회원 탈퇴 시 개인정보',
    date: '회원 탈퇴 후 30일간 보관 후 파기',
    reason: '서비스 이용 이력 확인 및 부정 이용 방지',
  },
  {
    key: '2',
    item: '이벤트 신청 정보(상담)',
    date: '상담 종료 후 1년간 보관 후 파기',
    reason: '고객 문의 대응 및 분쟁 해결',
  },
  {
    key: '3',
    item: '어뷰징 및 부정 이용 기록',
    date: '3년간 보관후 파기',
    reason: '부정 이용 방지 및 법적 대응',
  },
]

// 관계 법령 제공 ----------
export const relationColmun = [
  {
    title: '보존 항목',
    dataIndex: 'item',
    width: '33%',
  },
  {
    title: '근거 법령',
    dataIndex: 'reason',
    width: '33%',
  },
  {
    title: '보존 기간',
    dataIndex: 'date',
    width: '33%',
  },
]

export const relationDataSource = [
  {
    key: '1',
    item: '계약 또는 청약 철회등에 관한기록',
    reason: '전자상거래 등에서의 소비자 보호에 관한 법률',
    date: '5년',
  },
  {
    key: '2',
    item: '대금결제 및 재화 등의 공급에 관한 기록',
    reason: '전자상거래 등에서의 소비자 보호에 관한 법률',
    date: '5년',
  },
  {
    key: '3',
    item: '소비자의 불만 또는 분쟁 처링에 관한 기록',
    reason: '전자상거래 등에서의 소비자 보호에 관한 법률',
    date: '3년',
  },
  {
    key: '4',
    item: '표시/광고에 관한 기록',
    reason: '전자상거래 등에서의 소비자 보호에 관한 법률',
    date: '6개월',
  },
  {
    key: '5',
    item: '전자금융 거래에 관한 기록',
    reason: '전자금융거래법',
    date: '5년',
  },
  {
    key: '6',
    item: '서비스 방문 기록',
    reason: '통신비밀보호법',
    date: '3개월',
  },
]
// 개인정보 보호 책임자 제공 ----------
export const protectedColmun = [
  {
    title: '구분',
    dataIndex: 'name',
    width: '20%',
  },
  {
    title: '개인정보 보호책임자',
    dataIndex: 'team',
    width: '40%',
  },
  {
    title: '개인정보 보호담당자',
    dataIndex: 'number',
    width: '40%',
  },
]

export const protectedDataSource = [
  {
    key: '1',
    name: '이름',
    team: '김홍민',
    number: '박승재',
  },
  {
    key: '2',
    name: '직책 및 부서',
    team: '대표이사',
    number: '개발팀 대리',
  },
  {
    key: '3',
    name: '연락처',
    team: 'madcom_@naver.com',
    number: '☎ 02-6925-5866(10:00 ~ 19:00)',
  },
]

// 개정이력 제공 ----------
export const historyColmun = [
  {
    title: '연번',
    dataIndex: 'num',
    width: '20%',
  },
  {
    title: '변경 일자',
    dataIndex: 'date',
    width: '30%',
  },
  {
    title: '적용 기간',
    dataIndex: 'admit',
    width: '50%',
  },
]

export const historyDataSource = [
  {
    key: '1',
    num: '1',
    date: '2024년 10월 19일',
    admit: '2024년 10월 20일부터 현재까지 적용 중',
  },
  {
    key: '2',
    num: '2',
    date: '2024년 02월 05일',
    admit: '2024년 02월 05일부터 2024년 10월 19일까지 적용',
  },
]
